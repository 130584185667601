import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function HeroSection() {
  useEffect(() => {
    // Dynamically load the Lottie player script
    const script = document.createElement("script");
    script.src =
      "https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs";
    script.type = "module";
    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="h-screen bg-gradient-to-br from-[#11c5dd] to-[#1b0e47] flex justify-center">
      <motion.header
        className="grid grid-cols-12 gap-0 w-full max-w-[2090px] max-h-max relative flex justify-center items-center text-left px-5 lg:mx-24 py-32 px-8 lg:py-20 md:px-10 md:py-40 overflow-hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        {/* Left Column (Text and Buttons) spanning 8 columns on large screens */}
        <div className="col-span-12 lg:col-span-7">
          <motion.h1
            className="text-white text-5xl sm:text-4xl md:text-5xl lg:text-left lg:text-5xl xl:text-5xl font-bold leading-tight mb-4"
            initial={{ y: 0 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.7, ease: "easeOut" }}
          >
            Websites That Grow with You
          </motion.h1>
          <motion.p
            className="text-white md:max-w-[90%] lg:text-left text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl mb-2 lg:mb-4"
            initial={{ x: 0 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.7, ease: "easeOut", delay: 0.5 }}
          >
            Built for performance, designed for growth - get a website that
            works as hard as you do, without the upfront expense
          </motion.p>
          <div className="hidden lg:items-start col-span-12 lg:col-span-7 lg:flex lg:gap-8 lg:px-4 flex lg:justify-start justify-center gap-6 px-2 mt-6 lg:mt-0">
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <Link
                to="/contact"
                className="inline-block px-4 sm:px-8 py-3 sm:py-4 text-white bg-[#00c9a7] border-2 border-[#00c9a7] rounded-md transition-all duration-300 text-base sm:text-2xl"
              >
                Book a Call
              </Link>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <Link
                to="/home#pricing"
                className="inline-block px-4 sm:px-8 py-3 sm:py-4 bg-white text-[#00c9a7] border-2 border-[#00c9a7] rounded-md transition-all duration-300 text-base sm:text-2xl"
              >
                View Pricing
              </Link>
            </motion.div>
          </div>
        </div>

        {/* Right Column (Lottie Animation) spanning 5 columns on large screens */}
        <div className="col-span-12 lg:col-span-5">
          <motion.div
            className="max-w-[300px] sm:max-w-[400px] md:max-w-[500px] lg:max-w-[600px] xl:max-w-[900px] h-auto bg-transparent mx-auto lg:mx-0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: `
                    <dotlottie-player
                      src="https://lottie.host/0d302278-1f00-4309-ba29-0afd0e68b211/qgv9zTahy5.json"
                      background="transparent"
                      speed="1"
                      style="width: 100%; height: auto;"
                      loop autoplay
                    ></dotlottie-player>
                  `,
              }}
            />
          </motion.div>
        </div>

        {/* Buttons that will be moved below the Lottie animation on tablets */}
        <div className="lg:items-start col-span-12 lg:col-span-7 lg:hidden lg:gap-8 lg:px-4 flex lg:justify-start justify-center gap-6 px-2 mt-6 lg:mt-0">
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <Link
              to="/contact"
              className="inline-block px-4 sm:px-8 py-3 sm:py-4 text-white bg-[#00c9a7] border-2 border-[#00c9a7] rounded-md transition-all duration-300 text-base sm:text-2xl"
            >
              Book a Call
            </Link>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <Link
              to="/home#pricing"
              className="inline-block px-4 sm:px-8 py-3 sm:py-4 bg-white text-[#00c9a7] border-2 border-[#00c9a7] rounded-md transition-all duration-300 text-base sm:text-2xl"
            >
              View Pricing
            </Link>
          </motion.div>
        </div>
      </motion.header>
    </div>
  );
}

export default HeroSection;
