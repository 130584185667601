"use client";
import React, { useEffect, useId, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { OutClick } from "./OutClick";
import bg from "../../assets/images/bg.jpg";
import b2 from "../../assets/images/b2.jpg";
import b3 from "../../assets/images/b3.jpg";
import b4 from "../../assets/images/b4.jpg";

export function CaseStudy() {
  const [active, setActive] = useState(null);
  const id = useId();
  const ref = useRef(null);

  useEffect(() => {
    function onKeyDown(event) {
      if (event.key === "Escape") {
        setActive(false);
      }
    }

    if (active && typeof active === "object") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [active]);

  OutClick(ref, () => setActive(null));

  return (
    <>
      <div id="CaseStudy" className="px-0 py-12 md:py-16 md:px-2">
        <div className="text-center px-8">
          <h2 className="text-3xl md:text-5xl lg:text-6xl font-bold text-gray-800 mb-4">
            From Concept to Clicks: Real Success Stories
          </h2>
          <p className="text-lg md:text-3xl lg:text-4xl text-gray-600">
            See how we transformed ideas into reality.
          </p>
        </div>

        <AnimatePresence>
          {active && typeof active === "object" && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/20 h-full w-full z-10"
            />
          )}
        </AnimatePresence>

        <AnimatePresence>
          {active && typeof active === "object" ? (
            <div className="fixed inset-0 grid place-items-center z-[100]">
              <motion.button
                key={`button-${active.title}-${id}`}
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{
                  opacity: 0,
                  transition: {
                    duration: 0.05,
                  },
                }}
                className="flex absolute top-2 right-2 items-center justify-center bg-white rounded-full h-6 w-6"
                onClick={() => setActive(null)}
              >
                <CloseIcon />
              </motion.button>
              <motion.div
                layoutId={`card-${active.title}-${id}`}
                ref={ref}
                className="w-full max-w-[90%] max-h-[800px]  md:h-fit md:max-h-[90%] flex flex-col md:flex-row bg-white dark:bg-neutral-900 sm:rounded-3xl overflow-hidden"
              >
                <motion.div
                  className="max-h-[100%] md:w-6/12 md:h-full"
                  layoutId={`image-${active.title}-${id}`}
                >
                  <img
                    width={200}
                    height={200}
                    src={active.src}
                    alt={active.title}
                    className="w-full h-full object-cover"
                  />
                </motion.div>

                <div className="w-full h-6/12 md:w-6/12 md:h-full p-6 flex flex-col justify-center">
                  <motion.h3
                    layoutId={`title-${active.title}-${id}`}
                    className="text-xl text-neutral-700 dark:text-neutral-200 text-base mb-2"
                  >
                    {active.title}
                  </motion.h3>
                  <motion.p
                    layoutId={`description-${active.description}-${id}`}
                    className="text-neutral-600 text-lg text-left dark:text-neutral-400 text-base mb-4"
                  >
                    {active.description}
                  </motion.p>

                  <motion.div
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="text-neutral-600 text-xs md:text-sm lg:text-base h-40 md:h-fit pb-10 flex flex-col pb-12 items-start gap-4 overflow-auto dark:text-neutral-400 [mask:linear-gradient(to_bottom,white,white,white, transparent)] [scrollbar-width:none] [-ms-overflow-style:none] [-webkit-overflow-scrolling:touch]"
                  >
                    {typeof active.content === "function"
                      ? active.content()
                      : active.content}
                  </motion.div>
                  <motion.a
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    href={active.ctaLink}
                    target="_blank"
                    className="px-4 py-3 text-sm rounded-full font-bold bg-green-500 text-white mt-4"
                  >
                    {active.ctaText}
                  </motion.a>
                </div>
              </motion.div>
            </div>
          ) : null}
        </AnimatePresence>

        {/* Modified grid layout for the cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 lg:gap-6 py-12 md:py-24 max-w-[100%] lg:max-w-[90%] mx-auto px-4">
          {/* First horizontal card */}
          <motion.div
            layoutId={`card-${cards[0].title}-${id}`}
            key={cards[0].title}
            onClick={() => setActive(cards[0])}
            className="relative col-span-1 md:col-span-2 cursor-pointer rounded-xl overflow-hidden" // Set container as relative
          >
            <img
              width={100}
              height={100}
              src={cards[0].src}
              alt={cards[0].title}
              className=" z-10 w-full h-[800px] object-cover rounded-lg" // Height adjusted for second horizontal card
            />
            <img
              width={100}
              height={100}
              src={cards[0].src}
              alt={cards[0].title}
              className="absolute z-0 w-full h-[800px] object-cover rounded-lg" // Height adjusted for second horizontal card
            />

            <div className="absolute z-10 bottom-0 left-0 p-4 bg-opacity-50 text-white w-full">
              <h2 className="text-lg font-semibold">{cards[0].title}</h2>
              <p className="text-left"> {cards[0].description}</p>
            </div>
          </motion.div>

          {/* Two vertical cards */}
          {[1, 2].map((index) => (
            <motion.div
              layoutId={`card-${cards[index].title}-${id}`}
              key={cards[index].title}
              onClick={() => setActive(cards[index])}
              className="relative bg-white rounded-xl shadow-lg cursor-pointer hover:bg-neutral-50 dark:hover:bg-neutral-800"
            >
              <img
                width={100}
                height={100}
                src={cards[index].src}
                alt={cards[index].title}
                className="w-full h-[800px] object-cover rounded-lg" // Height adjusted for second horizontal card
              />
              <div className="absolute z-10 bottom-0 left-0 p-4 bg-opacity-50 text-white w-full">
                <h2 className="text-lg font-semibold">{cards[index].title}</h2>
                <p className="text-left"> {cards[index].description}</p>
              </div>
            </motion.div>
          ))}

          {/* Second horizontal card */}
          <motion.div
            layoutId={`card-${cards[3].title}-${id}`}
            key={cards[3].title}
            onClick={() => setActive(cards[3])}
            className="relative col-span-1 md:col-span-2 cursor-pointer rounded-xl overflow-hidden" // Set container as relative
          >
            <img
              width={100}
              height={100}
              src={cards[3].src}
              alt={cards[3].title}
              className="w-full h-[800px] object-cover rounded-lg" // Height adjusted for second horizontal card
            />
            <div className="absolute z-10 bottom-0 left-0 p-4 bg-opacity-50 text-white w-full">
              <h2 className="text-lg font-semibold">{cards[3].title}</h2>
              <p className="text-left"> {cards[3].description}</p>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}

export const CloseIcon = () => {
  return (
    <motion.svg
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
        transition: {
          duration: 0.05,
        },
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="240"
      height="240"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="h-4 w-4 text-black"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M18 6l-12 12" />
      <path d="M6 6l12 12" />
    </motion.svg>
  );
};

const cards = [
  {
    description: "E-commerce with Custom 3D Avatar Try-On Technology",
    title: "Malivas",
    src: bg, // Replaced the image URL with the imported background image
    ctaText: "Visit",
    ctaLink: "https://ui.aceternity.com/templates",
    content: () => {
      return (
        <div>
          <h1 className="text-base md:text-xl md:text-left font-bold text-left">
            Overview:
          </h1>
          <p className="text-sm md:text-lg text-left ">
            Malivas, an innovative e-commerce store, offers users a unique
            shopping experience with a custom 3D avatar and try-on technology.
            The challenge was to seamlessly integrate this technology into the
            platform to enhance user experience and encourage engagement.
          </p>
          <hr className="border-t border-gray-300 my-4" />{" "}
          {/* Horizontal Line */}
          <h1 className="text-base md:text-xl md:text-left font-bold text-left">
            Solution: :
          </h1>
          <p className="text-sm md:text-lg text-left ">
            We built a scalable e-commerce platform that integrates 3D avatar
            try-on technology, allowing users to visualize products before
            purchasing. The intuitive interface ensures smooth navigation, while
            the 3D technology gives customers confidence in their shopping
            decisions. Technologies Used: ReactJS Three.js for 3D models Custom
            API for avatar rendering E-commerce integration
          </p>
          <hr className="border-t border-gray-300 my-4" />{" "}
          {/* Horizontal Line */}
          <h1 className="text-lg md:text-xl md:text-left font-bold text-left">
            Technologies Used:
          </h1>
          <ul className="md:text-lg text-left ">
            <li>Wordpress WooCommerce</li>
            <li>Three.js for 3D models</li>
            <li>Custom API for avatar rendering</li>
          </ul>
          <hr className="border-t border-gray-300 my-4" />{" "}
          {/* Horizontal Line */}
        </div>
      );
    },
  },
  {
    description: "Edtech Platform for Improving English Skills",
    title: "Okkhor",
    src: b2,
    ctaText: "Visit",
    ctaLink: "https://ui.aceternity.com/templates",
    content: () => {
      return (
        <div>
          <h1 className="text-base md:text-xl md:text-left font-bold text-left">
            Overview:
          </h1>
          <p className="text-sm md:text-lg text-left ">
            Okkhor is an edtech platform designed to improve children's English
            skills using engaging quiz modules. The goal was to create an
            interactive, educational platform that makes learning fun and
            effective for kids.
          </p>
          <hr className="border-t border-gray-300 my-4" />{" "}
          {/* Horizontal Line */}
          <h1 className="text-base md:text-xl md:text-left font-bold text-left">
            Solution: :
          </h1>
          <p className="text-sm md:text-lg text-left ">
            We designed a kid-friendly interface with interactive quiz modules
            that adapt to different learning levels. The platform is
            mobile-responsive and incorporates gamification elements to keep
            young learners engaged and motivated.
          </p>
          <hr className="border-t border-gray-300 my-4" />{" "}
          {/* Horizontal Line */}
          <h1 className="text-lg md:text-xl md:text-left font-bold text-left">
            Technologies Used:
          </h1>
          <ul className="md:text-lg text-left ">
            <li>Wordpress</li>
            <li>Gamification Tools</li>
            <li>Custom quiz engine</li>
          </ul>
          <hr className="border-t border-gray-300 my-4" />{" "}
          {/* Horizontal Line */}
        </div>
      );
    },
  },

  {
    description: "Redesigning Lead Converting Landing Page for Banbase",
    title: "Banbase",
    src: b3,
    ctaText: "Visit",
    ctaLink: "https://ui.aceternity.com/templates",
    content: () => {
      return (
        <div>
          <h1 className="text-base md:text-xl md:text-left font-bold text-left">
            Overview:
          </h1>
          <p className="text-sm md:text-lg text-left ">
            Banbase, a Dutch-based software outsourcing agency, needed a
            high-converting landing page to capture leads and drive their
            outsourcing services. They were looking for a clean, professional,
            and conversion-focused design to effectively communicate their value
            proposition.
          </p>
          <hr className="border-t border-gray-300 my-4" />{" "}
          {/* Horizontal Line */}
          <h1 className="text-base md:text-xl md:text-left font-bold text-left">
            Solution: :
          </h1>
          <p className="text-sm md:text-lg text-left ">
            We crafted a visually appealing landing page that showcases
            Banbase’s offerings while optimizing for lead generation. The design
            emphasizes user experience with a strong call-to-action, clear
            messaging, and engaging visuals that align with the brand’s
            professionalism.
          </p>
          <hr className="border-t border-gray-300 my-4" />{" "}
          {/* Horizontal Line */}
          <h1 className="text-lg md:text-xl md:text-left font-bold text-left">
            Technologies Used:
          </h1>
          <ul className="md:text-lg text-left ">
            <li>React JS</li>
            <li>Lead Form Integration</li>
            <li>UI/UX Optimization</li>
          </ul>
          <hr className="border-t border-gray-300 my-4" />{" "}
          {/* Horizontal Line */}
        </div>
      );
    },
  },
  {
    description:
      "Lead-Generating Landing Page for an Education & Migration Consultancy",
    title: "OSHS Global",
    src: b4,
    ctaText: "Visit",
    ctaLink: "https://ui.aceternity.com/templates",
    content: () => {
      return (
        <div>
          <h1 className="text-base md:text-xl md:text-left font-bold text-left">
            Overview:
          </h1>
          <p className="text-sm md:text-lg text-left ">
            OSHS Global, an Australia-based education and migration consultancy,
            needed a professional landing page to capture potential client
            leads. The design had to be clear, informative, and focused on
            building trust with prospective clients.
          </p>
          <hr className="border-t border-gray-300 my-4" />{" "}
          {/* Horizontal Line */}
          <h1 className="text-base md:text-xl md:text-left font-bold text-left">
            Solution: :
          </h1>
          <p className="text-sm md:text-lg text-left ">
            We built a sleek landing page that highlights OSHS Global’s services
            with clear calls to action. The design is tailored to foster trust,
            with testimonials, service descriptions, and a well-placed contact
            form to generate leads efficiently.
          </p>
          <hr className="border-t border-gray-300 my-4" />{" "}
          {/* Horizontal Line */}
          <h1 className="text-lg md:text-xl md:text-left font-bold text-left">
            Technologies Used:
          </h1>
          <ul className="md:text-lg text-left ">
            <li>Wordpress</li>
            <li>HTML/CSS</li>
            <li>Lead Form Integration</li>
            <li>UI/UX Optimization</li>
          </ul>
          <hr className="border-t border-gray-300 my-4" />{" "}
          {/* Horizontal Line */}
        </div>
      );
    },
  },
];
