import React from "react";
import money from "../assets/images/Money.png"; // Assuming you have a logo
import superpowers from "../assets/images/superpowers.jpg"; // Assuming you have a logo
import team from "../assets/images/team.jpg"; // Assuming you have a logo

function TeamSection() {
  return (
    <div className="w-full py-12 space-y-12 bg-gray-100">
      {" "}
      {/* Removed container and added full width */}
      {/* First Section - Text on Left, Image on Right */}
      <div className="flex flex-col-reverse lg:flex-row items-center px-6 lg:px-24">
        {" "}
        {/* Added padding only for content */}
        <div className="lg:w-1/2 p-6">
          <h3 className="uppercase text-gray-800 text-sm sm:text-base md:text-2xl lg:text-2xl lg:text-left xl:text-left xl:text-2xl mb-2 text-center">
            Unleashing your Superpowers
          </h3>
          <h2 className="text-lg sm:text-4xl md:text-4xl lg:text-5xl xl:text-5xl font-bold mb-4 lg:text-left xl:text-left text-black text-center">
            Committed to elevate your business
          </h2>
          <p className="text-gray-600 text-center sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl lg:text-left xl:text-left text-sm">
            We believe that great websites are like superheroes—powerful,
            reliable, and always ready to save the day (or at least, improve
            your online presence). Whether you’re looking to launch your next
            big thing or give your current site a much-needed makeover, we’ve
            got the tools, the tech, and the talent to make it happen.
          </p>
        </div>
        <div className="lg:w-1/2 p-6 flex justify-center">
          <img
            src={superpowers}
            alt="Sample"
            className="rounded-lg  max-w-[60%] h-auto" // Ensures image is responsive and fits well
            style={{ width: "400px", height: "auto" }} // Optional: Adjust to specific size as needed
          />
        </div>
      </div>
      {/* Second Section - Image on Left, Text on Right */}
      <div className="flex flex-col lg:flex-row items-center px-6 md:px-24">
        {" "}
        {/* Added padding only for content */}
        <div className="lg:w-1/2 p-6 flex justify-center">
          <img
            src={team}
            alt="Sample"
            className="rounded-lg  max-w-[60%] h-auto"
          />
        </div>
        <div className="lg:w-1/2 p-6">
          <h3 className="uppercase text-gray-800 text-sm sm:text-base md:text-2xl lg:text-2xl lg:text-left xl:text-left xl:text-2xl mb-2 text-center">
            Your Superheroes
          </h3>
          <h2 className="text-lg sm:text-4xl md:text-4xl lg:text-5xl xl:text-5xl font-bold mb-4 lg:text-left xl:text-left text-black text-center">
            Web Development Experts
          </h2>
          <p className="text-gray-600 text-center sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl lg:text-left xl:text-left text-sm">
            A quirky bunch of designers, developers, and digital enthusiasts who
            are all about turning complex problems into simple, scalable
            solutions. We like to think of ourselves as part geeks, part
            creative geniuses—and we’re pretty proud of that.
          </p>
        </div>
      </div>
      {/* First Section - Text on Left, Image on Right */}
      <div className="flex flex-col-reverse lg:flex-row items-center px-6 md:px-24">
        {" "}
        {/* Added padding only for content */}
      </div>
    </div>
  );
}

export default TeamSection;
