import React, { useState } from "react";
import logo from "../assets/images/whiteLogo.png"; // Assuming you have a logo
import styled from "styled-components";
import { Link } from "react-router-dom";

function Nav2() {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to toggle hamburger menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-transparent absolute left-0 w-full z-50">
      <div className="max-w-full mx-auto px-4 sm:px-4 lg:px-6">
        <div className="grid grid-cols-12 items-center h-16">
          {/* Left Section - Logo */}
          <div className="lg:pl-24 col-span-4 flex justify-center items-center">
            <a href="/" className="text-xl font-bold">
              <img
                src={logo}
                alt="Logo"
                className="lg:max-w-[70%] h-auto w-auto"
              />
            </a>
          </div>

          {/* Hamburger Menu Icon (Visible on small and medium screens) */}
          <div className="col-span-8 flex justify-end items-center lg:hidden">
            <button
              className={`burger ${
                isMenuOpen ? "open" : ""
              } fixed top-5 right-5 z-50`} // Ensure button is fixed and on top
              onClick={toggleMenu}
            >
              {/* Change icon based on menu state */}
              {isMenuOpen ? (
                <svg
                  className="w-8 h-8 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="w-8 h-8 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              )}
            </button>
          </div>

          {/* Right Section - Links and CTA (Hidden on small and medium screens) */}
          <div
            className={`justify-end col-span-8 lg:flex hidden lg:block space-x-6 items-center`}
          >
            <Link
              to="/home#CaseStudy" // This is the id of the section you want to scroll to
              smooth={true} // Enables smooth scrolling
              duration={500} // Duration of the scroll in milliseconds
              className="text-gray-200 pb-4 hover:text-white"
              style={{ fontSize: "clamp(1rem, 2vw, 1.3rem)" }}
            >
              Our Work
            </Link>
            <Link
              to="/home#pricing"
              className="text-gray-200 pb-4 hover:text-white"
              style={{ fontSize: "clamp(1rem, 2vw, 1.3rem)" }}
            >
              Pricing
            </Link>
            <Link
              to="/about"
              className="text-gray-200 pb-4 hover:text-white"
              style={{ fontSize: "clamp(1rem, 2vw, 1.3rem)" }}
            >
              About Us
            </Link>

            {/* Get In Touch - Learn More Styled Button */}
            <StyledWrapper>
              <Link to="/contact">
                <button className="learn-more">
                  <span className="circle" aria-hidden="true">
                    <span className="icon arrow mx-8" />
                  </span>

                  <span
                    className="button-text"
                    style={{ fontSize: "clamp(1rem, 2vw, 1.3rem)" }}
                  >
                    Get In Touch
                  </span>
                </button>
              </Link>
            </StyledWrapper>
          </div>
        </div>

        {/* Fullscreen Overlay Menu for Small Screens */}
        <div
          className={`fixed inset-0 bg-black bg-opacity-90 z-40 transition-opacity duration-500 ease-in-out ${
            isMenuOpen ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        >
          <div className="flex flex-col items-center justify-center h-full">
            <Link
              to="/home#CaseStudy" // This is the id of the section you want to scroll to
              smooth={true} // Enables smooth scrolling
              duration={500} // Duration of the scroll in milliseconds
              className="text-gray-200 py-4 text-3xl hover:text-white"
              style={{ fontSize: "clamp(1.5rem, 4vw, 2.5rem)" }}
              onClick={toggleMenu}
            >
              Our Work
            </Link>
            <Link
              to="/home#pricing"
              className="text-gray-200 py-4 text-3xl hover:text-white"
              style={{ fontSize: "clamp(1.5rem, 4vw, 2.5rem)" }}
              onClick={toggleMenu}
            >
              Pricing
            </Link>
            <Link
              to="/about"
              className="text-gray-200 py-4 text-3xl hover:text-white"
              style={{ fontSize: "clamp(1.5rem, 4vw, 2.5rem)" }}
              onClick={toggleMenu}
            >
              About Us
            </Link>
            <Link
              to="/contact"
              className="text-gray-200 py-4 text-3xl hover:text-white"
              style={{ fontSize: "clamp(1.5rem, 4vw, 2.5rem)" }}
              onClick={toggleMenu}
            >
              Get In Touch
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

const StyledWrapper = styled.div`
  button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
  }

  button.learn-more {
    width: 12rem;
    height: auto;
  }

  button.learn-more .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: transparent;
    border-radius: 1.625rem;
  }

  button.learn-more .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
  }

  button.learn-more .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: -0.375rem;
    width: 1.125rem;
    height: 0.125rem;
    top: -1rem;
    background: none;
  }

  button.learn-more .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.4rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    transform: rotate(45deg);
  }

  button.learn-more .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 0;
    margin: 0 0 0 1.85rem;
    color: #e5e7eb;
    font-weight: 400;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
  }

  button:hover .circle {
    width: 110%;
    top: 0rem;
    background: #00c9a7;
  }

  button:hover .circle .icon.arrow {
    background: #fff;
    transform: translate(1rem, 0);
  }

  button:hover .button-text {
    color: #fff;
  }
`;

export default Nav2;
