import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Consult = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true); // Start loader
    setSuccessMessage("");
    setErrorMessage("");

    emailjs
      .sendForm(
        "service_dbnadco", // e.g., "service_xyz"
        "template_ro9zbkc", // e.g., "template_abc"
        form.current,
        "rZyhEUHrVdrTRm7uA" // e.g., "user_publicKey123"
      )
      .then(
        () => {
          setLoading(false); // Stop loader
          setSuccessMessage("Your message has been sent successfully!");
          form.current.reset(); // Reset the form after success
        },
        (error) => {
          setLoading(false); // Stop loader
          setErrorMessage("Failed to send message. Please try again later.");
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <section className="bg-[#0fa4af] h-[90%] text-white py-12 px-4 md:py-16">
      <div className="max-w-7xl h-[80%] mx-auto py-24 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        {/* Left Section */}
        <div className="text-center md:text-left">
          <h1 className="text-5xl md:text-left md:text-7xl font-bold leading-tight text-[#FF8551]">
            <span className="text-white">Let's Talk</span>
          </h1>
          <p className="mt-4 text-xl px-8 md:px-0 md:text-left md:text-3xl text-lg">
            We’ve got the tools and expertise to elevate your brand.
          </p>
        </div>

        {/* Right Section - Form */}
        <div className="bg-white text-[#1D3B34] rounded-lg p-6 md:p-8 shadow-lg">
          <h2 className="text-xl font-semibold mb-4 text-center md:text-left">
            Get a Free Consultation
          </h2>
          <p className="text-sm mb-6 text-center md:text-left">
            A hassle-free 15-minute chat with one of our experts to discover the
            ideal website solution for your business needs.
          </p>

          <form ref={form} onSubmit={sendEmail}>
            <div className="mb-4">
              <label htmlFor="name" className="sr-only">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="from_name"
                placeholder="Name"
                className="w-full border border-gray-300 p-3 rounded-md"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="phone" className="sr-only">
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                name="user_phone"
                placeholder="Phone number *"
                className="w-full border border-gray-300 p-3 rounded-md"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="sr-only">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="user_email"
                placeholder="Email address *"
                className="w-full border border-gray-300 p-3 rounded-md"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="message" className="sr-only">
                Message
              </label>
              <textarea
                id="message"
                name="user_message"
                placeholder="Your message (optional)"
                className="w-full border border-gray-300 p-3 rounded-md"
              />
            </div>

            {loading && (
              <div className="mb-4 text-center">
                <svg
                  className="animate-spin h-5 w-5 text-gray-900 inline"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8z"
                  ></path>
                </svg>
                <span className="ml-2">Sending...</span>
              </div>
            )}

            {successMessage && (
              <div className="mb-4 text-green-500 text-center">
                {successMessage}
              </div>
            )}

            {errorMessage && (
              <div className="mb-4 text-red-500 text-center">
                {errorMessage}
              </div>
            )}

            <button
              type="submit"
              className="w-full bg-[#00c9a7] text-white py-3 px-6 rounded-md font-semibold"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Book a Free Consultation"}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Consult;
