import React from "react";
import Consult from "../components/Popup";
import Nav2 from "../components/Nav2";
import Footer from "../components/Footer";

function ContactPage() {
  return (
    <div>
      <Nav2 />
      <Consult />
      <Footer />
    </div>
  );
}

export default ContactPage;
