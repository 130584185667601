// src/pages/AboutUsPage.js
import React from "react";
import { Link } from "react-router-dom";
import Hero2 from "../components/Hero/Hero2";
import Nav2 from "../components/Nav2";
import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials";
import CTASection from "../components/CTASection";
import Section from "../components/TeamValue";
import { CaseStudy } from "../components/CaseStudy/CaseStudy";
import Why from "../components/Why";

const AboutPage = () => {
  return (
    <div>
      <Nav2 />
      <Hero2 />
      <Why />
      <Section />

      <Testimonials />
      <CTASection />
      <Footer />
    </div>
  );
};
export default AboutPage;
