import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./tailwind.css";
import HomePage from "./pages/HomePage";
import ServicesPage from "./pages/ServicesPage";
import ContactPage from "./pages/ContactPage";
import AboutPage from "./pages/AboutPage";
import Loader from "./components/Loader"; // Import the loader component
import "./App.css";
import "./components/clash-display.css"; // Make sure the path is correct
import ScrollToTop from "./lib/ScrollTop";

function App() {
  const [isLoading, setIsLoading] = useState(true); // State to track loading

  useEffect(() => {
    // Simulate a loading time (e.g., content fetch, API request, etc.)
    const timer = setTimeout(() => {
      setIsLoading(false); // Set loading to false after 3 seconds
    }, 100); // Adjust the time as needed

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        <Loader /> // Show the Loader component while loading
      ) : (
        // Once loading is complete, show the routes
        <>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </>
      )}
    </div>
  );
}

export default App;
