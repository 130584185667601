import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CaseStudy } from "../components/CaseStudy/CaseStudy";
import Nav2 from "../components/Nav2";
import HeroSection from "../components/Hero/HeroSection";
import CTASection from "../components/CTASection";
import ValuesSection from "../components/ValueSection";
import Card from "../components/Price/GradCard";
import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials";
import { ContactUs } from "../components/Contact1";

function HomePage() {
  const location = useLocation();

  useEffect(() => {
    // Check if there's a hash in the URL
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1)); // Get element by id
      if (element) {
        element.scrollIntoView({ behavior: "smooth" }); // Scroll smoothly to the element
      }
    }
  }, [location]);

  return (
    <div>
      <Nav2 />
      <HeroSection />
      <ValuesSection />
      <Card id="pricing" />
      <CaseStudy id="CaseStudy" /> {/* Add the id here */}
      <Testimonials />
      <CTASection />
      <Footer />
    </div>
  );
}

export default HomePage;
