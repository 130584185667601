import React, { useState } from "react";
import styled from "styled-components";
import Tier1 from "./Tier1"; // Assuming you have a separate PriceCard component
import Tier2 from "./Tier2";
import Tier3 from "./Tier3";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "../Hero/HeroSection.css";

const Card = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleSelectCard = (index) => {
    setSelectedCard(index); // Update the selected card state
  };

  const handleBuyNow = () => {
    <Link to="/contact"></Link>;
    // if (selectedCard !== null) {
    //   alert(`You selected card ${selectedCard + 1}`);
    //   // You can also handle further actions here like API calls or state updates
    // } else {
    //   alert("Please select a plan");
    // }
  };

  return (
    <StyledWrapper>
      {/* Add a relevant header and subtext */}
      <section id="pricing" className="p-4 0 sm:p-20 text-center bg-[#1b1b1b]">
        <div
          className="py-16 text-center rounded-3xl"
          style={{
            backgroundImage: "linear-gradient(20deg, #cfd9df 0%, #e2ebf0 100%)",
          }}
        >
          <h2 className="header text-lg md:text-3xl font-bold text-gray-800 mb-6">
            No More Excuses. Just Great Websites
          </h2>
          <p className="header text-sm md:text-xl px-2 text-gray-600 mb-10">
            Choose your plan, and let us handle the magic behind your online
            presence. It's that simple.
          </p>

          <div className="card-container">
            {/* Rendering three PriceCard components side by side */}
            {[0, 1, 2].map((index) => {
              if (index === 0) {
                return (
                  <Tier1
                    key={index}
                    // isSelected={selectedCard === index}
                    // onClick={() => handleSelectCard(index)}
                  />
                );
              } else if (index === 1) {
                return (
                  <Tier2
                    key={index}
                    // isSelected={selectedCard === index}
                    // onClick={() => handleSelectCard(index)}
                  />
                );
              } else if (index === 2) {
                return (
                  <Tier3
                    key={index}
                    // isSelected={selectedCard === index}
                    // onClick={() => handleSelectCard(index)}
                  />
                );
              }
            })}
          </div>

          {/* Center the button with improved styling */}

          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <button>
              <Link
                to="/contact"
                className="inline-block px-4 sm:px-8 py-3 sm:py-4 text-white bg-[#00c9a7] border-2 border-[#00c9a7] rounded-md transition-all duration-300 text-base sm:text-2xl"
              >
                Book a Call
              </Link>
            </button>
          </motion.div>
        </div>
      </section>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .BuyNow {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 20px;
  }

  .primary-btn {
    background-color: #ff5722; /* Use your brand color */
    color: white;
    font-size: clamp(1rem, 2.5vw, 1.125rem); /* Responsive font size */
    font-weight: 600;
    padding: clamp(0.5rem, 2vw, 0.75rem) clamp(1.5rem, 4vw, 2.5rem); /* Responsive padding */
    border-radius: 8px;
    transition: transform 0.2s ease, background-color 0.2s ease;

    &:hover {
      transform: scale(1.05); /* Slight scale on hover */
      background-color: #e64a19; /* Slightly darker brand color on hover */
    }
  }

  .card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px; /* Spacing between the cards */
    padding: 60px;
    flex-wrap: wrap; /* Enable wrapping for smaller screens */
    max-width: 100%; /* Prevent overflow on smaller screens */

    @media (max-width: 768px) {
      flex-direction: column; /* Stack the cards vertically on small screens */
      gap: 20px;
      padding: 20px;
    }
  }

  /* Styling for Price Cards */
  .card {
    flex-direction: column;
    padding: clamp(10px, 2vw, 20px); /* Responsive padding */
    margin: clamp(50px, 2vw, 100px); /* Responsive margin */
    text-align: center;
    cursor: pointer;
    box-shadow: 0 10px 15px -3px rgba(33, 150, 243, 0.4),
      0 4px 6px -4px rgba(33, 150, 243, 0.4);
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(45deg, #04051dea 0%, #2b566e 100%);
    width: clamp(
      250px,
      90%,
      350px
    ); /* Ensure the cards do not exceed screen width */

    @media (max-width: 768px) {
      width: 100%; /* Full width on small screens */
    }
  }

  /* Selected card style */
  .card.selected {
    border: 2px solid #1d4ed8; /* Blue border to indicate selection */
    transform: scale(1.05); /* Slightly enlarge the selected card */
    background-color: #1d4ed8;
  }

  /* Responsive typography for headings and text */
  h2.header {
    font-size: clamp(
      1.5rem,
      2.8vw,
      3.5rem
    ); /* Responsive font size for heading */
  }

  p.header {
    font-size: clamp(1rem, 2vw, 2rem); /* Responsive font size for paragraph */
  }

  p.price {
    font-size: clamp(
      2.5rem,
      2vw,
      3rem
    ); /* Responsive font size for paragraph */
  }

  p {
    font-size: clamp(
      1.2rem,
      2vw,
      1.8rem
    ); /* Responsive font size for paragraph */
  }

  li {
    font-size: clamp(
      1rem,
      2vw,
      1.1rem
    ); /* Responsive font size for paragraph */
  }
`;

export default Card;
