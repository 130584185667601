// src/components/CTASection.js
import React from "react";
import "./CTASection.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function CTASection() {
  return (
    <section className="cta-section bg-[#0c4b7a] text-center text-white py-24 px-4 h-screen lg:h-max  flex justify-center items-center">
      <div className="cta-content md:grid grid-cols-12 px-4 lg:px-36 ">
        <div className="col-span-12">
          <h2 className="text-4xl sm:col-span-5 pb-8 flex text-center flex-col  md:text-6xl font-bold">
            Ready to Elevate Your Business?
          </h2>
          <p className="text-xl text-center md:text-2xl">
            Contact us today and let's discuss how we can help your business
            grow with a scalable, future-proof website.
          </p>
        </div>

        <div className="md:col-span-12 place-items-center text-center">
          <div className="cta-buttons pt-12 md:col-span-4">
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <Link
                to="/contact"
                className="inline-block px-4 sm:px-8 py-3 sm:py-4 text-white bg-[#00c9a7] border-2 border-[#00c9a7] rounded-md transition-all duration-300 text-base sm:text-2xl"
              >
                Book a Call
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CTASection;
