import React from "react";
import logo from "../assets/images/whiteLogo.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-gray-900 text-white py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-12 gap-4">
        {/* Logo Section */}
        <div className="col-span-12 sm:col-span-4 flex flex-col items-center">
          <a href="/" className="text-xl font-bold">
            <img src={logo} alt="Logo" className="w-4/5 h-auto mb-1" />
          </a>
          <p className="text-lg text-left">
            Helping businesses scale with future-proof, high-performance
            websites.
          </p>
        </div>

        {/* Links Section */}
        <div className="md:px-12 col-span-12 sm:col-span-4 grid grid-cols-2 gap-4 text-left sm:mt-8 sm:text-left">
          <ul className="sm:col-span-4 col-span-12 text-lg text-left sm:text-left mt-2">
            <li className="pb-4">
              2 Figtree Drive, Sydney Olympic Park, NSW 2127, Australia
            </li>
            <li className="pb-4">+61424395793</li>
            <li className="pb-0">hello@cacheflowsites.com</li>
          </ul>
        </div>
        <div className="col-span-12 sm:col-span-4 grid grid-cols-2 gap-4 text-left text-lg sm:mt-8 sm:text-left">
          <div className="space-y-2">
            <Link
              to="/home"
              className="text-center sm:text-center hover:underline"
            >
              Home
            </Link>
            <Link
              to="/about"
              className="text-center sm:text-center hover:underline"
            >
              About Us
            </Link>
          </div>
          <div className="space-y-2">
            <Link
              to="/home#pricing"
              className="text-center sm:text-center hover:underline"
            >
              Pricing
            </Link>
            <Link
              to="/contact"
              className="text-center sm:text-center hover:underline"
            >
              Get In Touch
            </Link>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-12 grid grid-cols-12 gap-4 text-left sm:text-left">
          {/* Social Media Links */}
          <div className="hidden col-span-12 sm:col-span-12 flex justify-center sm:justify-end space-x-6 mt-6 sm:mt-0">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              Facebook
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              Twitter
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              LinkedIn
            </a>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="mt-10 border-t border-gray-700 pt-4">
        <div className="text-center">
          <p className="text-sm">© 2024 CacheFlow. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
