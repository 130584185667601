import React from "react";

function Why() {
  return (
    <section className="bg-gray-950 text-white py-16">
      <div className="container mx-auto text-center md:text-left px-6 md:px-12">
        {/* Section Heading */}
        <h2 className="text-3xl md:text-5xl lg:text-6xl font-bold mb-4">
          Why Great Websites Matter
        </h2>
        <p className="text-lg md:text-xl lg:text-2xl text-gray-400 max-w-3xl mx-auto md:text-left md:mx-0 mb-8">
          In a universe with a population of under 8 billion, we're here to tell
          you – great websites matter. They are the eco-warriors of the digital
          world, clearing the path for successful businesses.
        </p>

        {/* Stats Section */}
        <div className="flex flex-col md:flex-row md:justify-start justify-center items-center gap-12 md:gap-24">
          {/* Perfect Score */}
          <div className="flex flex-col items-center">
            <span className="text-5xl font-bold">100</span>
            <span className="text-lg text-gray-400">Perfect Score</span>
          </div>

          {/* Happy Clients */}
          <div className="flex flex-col items-center">
            <span className="text-5xl font-bold">10K</span>
            <span className="text-lg text-gray-400">Happy Clients</span>
          </div>

          {/* Golden Awards */}
          <div className="flex flex-col items-center">
            <span className="text-5xl font-bold">4</span>
            <span className="text-lg text-gray-400">Golden Awards</span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Why;
