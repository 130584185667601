import React from "react";
import money from "../assets/images/Money.png"; // Assuming you have a logo
import website from "../assets/images/Computer.png"; // Assuming you have a logo
import custom from "../assets/images/custom.png"; // Assuming you have a logo

function Section() {
  return (
    <div className="w-full py-12 space-y-12 bg-[#1b1b1b]">
      {" "}
      {/* Removed container and added full width */}
      {/* First Section - Text on Left, Image on Right */}
      <div className="flex flex-col-reverse lg:flex-row items-center px-6 lg:px-24">
        {" "}
        {/* Added padding only for content */}
        <div className="lg:w-1/2 p-6">
          <h3 className="uppercase text-gray-400 text-sm sm:text-base md:text-xl lg:text-xl lg:text-left xl:text-left xl:text-lg mb-2 text-center">
            Amazing and Effective Websites
          </h3>
          <h2 className="text-lg sm:text-4xl md:text-2xl lg:text-3xl xl:text-3xl font-bold mb-4 lg:text-left xl:text-left text-white text-center">
            Your Website, Your Business Catalyst
          </h2>
          <p className="text-gray-200 text-center sm:text-xl md:text-lg lg:text-xl xl:text-xl lg:text-left xl:text-left text-sm">
            From capturing attention to converting visitors into loyal
            customers, your website needs to be flawless. We don’t just build
            beautiful websites; we craft powerful digital experiences that
            accelerate your growth. Our custom-built sites are designed with
            speed, responsiveness, and user engagement at their core, ensuring
            your business stands out and thrives in a competitive market.
          </p>
        </div>
        <div className="lg:w-1/2 p-6 flex justify-center">
          <img
            src={website}
            alt="Sample"
            className="rounded-lg  max-w-[60%] h-auto" // Ensures image is responsive and fits well
            style={{ width: "400px", height: "auto" }} // Optional: Adjust to specific size as needed
          />
        </div>
      </div>
      {/* Second Section - Image on Left, Text on Right */}
      <div className="flex flex-col lg:flex-row items-center px-6 md:px-24">
        {" "}
        {/* Added padding only for content */}
        <div className="lg:w-1/2 p-6 flex justify-center">
          <img
            src={money}
            alt="Sample"
            className="rounded-lg  max-w-[60%] h-auto"
          />
        </div>
        <div className="lg:w-1/2 p-6">
          <h3 className="uppercase text-gray-400 text-sm sm:text-base md:text-xl lg:text-xl lg:text-left xl:text-left xl:text-lg mb-2 text-center">
            Affordable, Quality Websites with a Subscription Model
          </h3>
          <h2 className="text-lg sm:text-4xl md:text-2xl lg:text-3xl xl:text-3xl font-bold mb-4 lg:text-left xl:text-left text-white text-center">
            Top-Tier Quality, Pay-As-You-Grow
          </h2>
          <p className="text-gray-200 text-center sm:text-xl md:text-lg lg:text-xl xl:text-xl lg:text-left xl:text-left text-sm">
            Dreaming of an expensive, high-performance website but worried about
            the upfront cost? We’ve got you covered. You shouldn’t have to
            compromise on quality just because you’re starting out. Our
            subscription model gives you access to websites typically reserved
            for big-budget businesses—without the financial strain. Start
            building your online presence today and let your website pay for
            itself as your business grows. You focus on running your company;
            we’ll handle the tech.
          </p>
        </div>
      </div>
      {/* First Section - Text on Left, Image on Right */}
      <div className="flex flex-col-reverse lg:flex-row items-center px-6 md:px-24">
        {" "}
        {/* Added padding only for content */}
        <div className="lg:w-1/2 p-6">
          <h3 className="uppercase text-gray-400 text-sm sm:text-base md:text-xl lg:text-xl lg:text-left xl:text-left xl:text-lg mb-2 text-center">
            Continuous Support and Customization
          </h3>
          <h2 className="text-lg sm:text-4xl md:text-4xl lg:text-2xl xl:text-3xl font-bold mb-4 lg:text-left xl:text-left text-white text-center">
            Your Website, Always Evolving
          </h2>
          <p className="text-gray-200 text-center sm:text-xl md:text-lg lg:text-xl xl:text-xl lg:text-left xl:text-left text-sm">
            A website isn’t a one-time deal—it’s a living part of your business
            that should grow and adapt as you do. We’re here to ensure your site
            stays fresh, relevant, and in line with your changing needs. We
            provide regular content updates and minor customizations at no extra
            cost, ensuring your site stays fresh and aligned with your needs.
            For bigger changes, our team is always ready to help with major
            customizations at a fair price. No more struggling to reach
            developers or paying high fees to keep your website current.
          </p>
        </div>
        <div className="lg:w-1/2 p-6 flex justify-center">
          <img
            src={custom}
            alt="Sample"
            className="rounded-lg  max-w-[60%] h-auto" // Ensures image is responsive and fits well
            style={{ width: "400px", height: "auto" }} // Optional: Adjust to specific size as needed
          />
        </div>
      </div>
    </div>
  );
}

export default Section;
